@import '~@angular/material/theming';
@import "colorpalette";

@import 'https://fonts.googleapis.com/css?family=Material+Icons+Outlined';
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500');

$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 300, 'Ubuntu', -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, 'Ubuntu', -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, 'Ubuntu', 0.0000em),
  display-1: mat-typography-level(34px, 40px, 400, 'Ubuntu', 0.0074em),
  headline: mat-typography-level(24px, 32px, 400, 'Ubuntu', 0.0000em),
  title: mat-typography-level(20px, 32px, 500, 'Ubuntu', 0.0075em),
  subheading-2: mat-typography-level(16px, 28px, 400, 'Ubuntu', 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 500, 'Ubuntu', 0.0067em),
  body-2: mat-typography-level(14px, 24px, 500, 'Ubuntu', 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, 'Ubuntu', 0.0179em),
  button: mat-typography-level(14px, 14px, 500, 'Ubuntu', 0.0893em),
  caption: mat-typography-level(12px, 20px, 400, 'Ubuntu', 0.0333em),
  input: mat-typography-level(inherit, 1.125, 400, 'Ubuntu', 1.5px)
);

@include mat-core($fontConfig);


$wilmaplus-primary-palette: mat-palette($wilmaplus-primary, main, lighter, darker);
$wilmaplus-accent-palette:  mat-palette($wilmaplus-accent, main, lighter, darker);

$wilmaplus-dark-primary-palette: mat-palette($dark-primary, main, lighter, darker);
$wilmaplus-dark-accent-palette: mat-palette($dark-accent, main, lighter, darker);
$wilmaplus-dark-warn-palette: mat-palette($dark-warn, main, lighter, darker);

// Light Theme
$wilmaplus-theme: mat-light-theme((
  color: (
    primary: $wilmaplus-primary-palette,
    accent: $wilmaplus-accent-palette
  )
));

// Dark theme
$wilmaplus-dark-theme: mat-dark-theme((
  color: (
    primary: $wilmaplus-dark-primary-palette,
    accent: $wilmaplus-dark-accent-palette,
    warn: $wilmaplus-dark-warn-palette
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($wilmaplus-theme);


@media (prefers-color-scheme: dark) {
  @include angular-material-theme($wilmaplus-dark-theme);
  $selectedTheme: $wilmaplus-dark-theme;
  body {background-color: black; color: white;};
}

@mixin theme($property: null, $key: null, $key2: null) {
  & {
    #{$property}: mat-color(map_get($wilmaplus-theme, $key), $key2);
  }

  @media (prefers-color-scheme: dark) {
    #{$property}: mat-color(map_get($wilmaplus-dark-theme, $key), $key2);
  }
}

@mixin themeContrast($property: null, $key: null, $key2: null) {
  & {
    #{$property}: mat-contrast(map_get($wilmaplus-theme, $key), $key2);
  }

  @media (prefers-color-scheme: dark) {
    #{$property}: mat-contrast(map_get($wilmaplus-dark-theme, $key), $key2);
  }
}

@mixin themeBgGradient($property: null, $key: null) {
  & {
    #{$property}: linear-gradient(135deg, mat-color(map_get($wilmaplus-theme, $key), main) 35%, mat-color($dark-primary, darker) 100%);
  }

  @media (prefers-color-scheme: dark) {
    #{$property}: linear-gradient(135deg, mat-color(map_get($wilmaplus-dark-theme, $key), main) 35%, mat-color($dark-primary, darker) 100%);
  }
}

.login-button-dark {
  @include mat-button-color($wilmaplus-dark-theme);
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons Outlined', 'Material Icons';
  .mat-badge-content {
    font-family: 'Ubuntu';
  }
}
