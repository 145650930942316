$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);

$light-text: #ffffff;
$light-primary-text: $light-text;

$wilmaplus-accent: (
  main: #4ac438,
  lighter: #c9edc3,
  darker: #32af24,
  200: #4ac438, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);


$wilmaplus-primary: (
  main: #059fcd,
  lighter: #b4e2f0,
  darker: #0383ba,
  200: #059fcd, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);

// For dark theme


$dark-accent: (
  main: #00475d,
  lighter: #eed9bf,
  darker: #022f4b,
  200: #25538c, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);

$dark-primary: (
  main: #282828,
  lighter: #3b3b3b,
  darker: #181818,
  200: #646464, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);

$dark-warn: (
  main: #ff4400,
  lighter: #ffc7b3,
  darker: #ff2d00,
  200: #ff4400, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);

