
html, body { height: 100%; }
body { margin: 0; font-family: Ubuntu, "Helvetica Neue", sans-serif; }

.removePadding {
  padding: 0 !important;
}

.bottomSheetPadding {
  padding: 8px 16px;
}

.wilma-htmlbox > * {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.wilma-htmlbox > a, .wilma-htmlbox > * > a {
  filter: invert();
}

.wilma-htmlbox {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.emoji-picker-icon{
  font-size:20px;
  right: 86px;
  top: 17px;
}
